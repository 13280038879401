.msg-container {
  position: absolute;
  bottom: 12px;
  left: 12px;
}

.msg-btn {
  font-size: 40px;
  color: rgb(206, 210, 213);
  transition: transform 200ms ease-in-out;
  opacity: 0.5;
}

.msg-btn:hover {
  transform: scale(1.05);
  opacity: 1;
}

.msg-list {
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border:0;
  border-radius: 20px;
  position: absolute;
  width: max-content;
  max-width: 400px;
  max-height: 260px;
  overflow-y: auto;
  top: -5px;
  right: 50px;
  transition: transform 250ms ease-in-out;
  transform-origin: bottom left;
  translate: 100% -100%;
}

.content {
  position: relative;
}