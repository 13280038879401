.news-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, 300px);
  justify-content: space-between;
  grid-gap: 32px;
}
@media screen and (max-width: 480px) {
  .news-container {
    justify-content: space-around;
  }
}
.news-card {
  width: 300px;
}

.news-header-img {
  width: 300px;
  height: 200px;
  object-fit: cover;
}

.news-summary {
  margin-top: 4px;
}

.actions-container{
  margin: 8px;
  padding: 24px;
  border: 1px solid #d3d3d3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: scroll;
}

.action-header{
  margin: 0px;
  color:#2b2b2b;
  flex: 0 0;

}

.action-body{
  margin-top: 15px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.add-stock-controller-item-title{
  margin: 0;
}

.switch-title{
  margin-right: 10px;
}

.add-stock-controller-item{
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
}

.add-stock-table{
  width: 100%;
  margin-top: 10px;
}

.add-stock-row{
  display: flex;
    justify-content: space-between;
    padding: 5px 0px;
}

.add-stock-btn{
  width: 100%;
  margin-top: 24px;
}

.add-stock-title{
  flex: 0 0;
}

.add-stock-switch{
  flex: 0 0;
}

.add-stock-item{
  flex: 1 0;
}

.input-item{
  width: 100%;
}

.postback-container{
  background: red;
  height: 100%;
  padding: 25px
}

.limit-order-collapse {
  border: none;
}

.limit-order-collapse .ant-collapse-item {
  border: none;
}

.limit-order-collapse .ant-collapse-header {
  padding-left: 0;
  border: none;
  background: white;
  font-style: italic;
}

.limit-order-collapse .ant-collapse-content {
  border: none;
}

@media screen and (max-width: 480px) {
  .add-stock-info-btn{
    display: none;
  }

  #postback-section {
    order: 1;
    margin-top: 16px;
  }
}

.stats{
    display: inline;
}

.inner-headers{
    margin: 10px 0px 10px 0px;
}

.statusHeaders{
    margin-bottom: 10px;
}
.settings-toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.postback-info-container{
  width: 65%;
  height: 450px;
}

.postback-info-container-item{
  border-bottom: 1px solid #dddddd;
  transition: all 0.3s ease-out;
  cursor: pointer;
}

.showOnHover{
  opacity: 0;
  color: #ababab;
  transition: opacity 0.3s ease-out;
}

.postback-info-container-item:hover{
  background: #fff;
}
.postback-info-container-item:hover .showOnHover{
  opacity: 1;
}

.table-container{
  padding: 30px 20px;
  border-radius: 4px;
  background: #f3f3f3;
}

.postback-info-container > h3 h2{
  flex: 1 0
}

.mt40{
  margin-top: 40px;
}

.mb20{
  margin-bottom: 20px;
}

td th {
  margin: 5px 0px;
}

.stats{
    display: inline;
}

.headers{
    margin: 40px 0px 10px 0px;
}

.statusHeaders{
    margin-bottom: 10px;
}

.inputBoxes{
    margin: 10px 25px;
}

.dividerOr{
    display: flex;
    align-items: center;
}

.inputsContainer{
    display: flex;
    width: 90%;
    justify-content: center;
    align-items: start;;
    flex-direction: column;
}

.fetchBtn{
    margin: 10px 25px;   
}

@media screen and (max-width: 600px) {
    .inputsContainer {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    
  }

.mb12 {
  margin-bottom: 12px;
}

.m8 {
  margin: 8px;
}

.btn {
  width: 100%;
  margin-top: 24px;
}

.actions-container{
  margin: 8px;
  padding: 24px;
  border: 1px solid #d3d3d3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: scroll;
}

.action-header{
  margin-bottom: 32px;
  color:#2b2b2b;
  flex: 0 0;
}

.action-header{
  margin-bottom: 32px;
  color:#2b2b2b;
  flex: 0 0;
}

.smallplug-container {
    height: 100vh;
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.smallplug-btn {
    margin-top: 16px;
}

.params-input {
    margin-top: 10px;
}
.mb12 {
  margin-bottom: 12px;
}

.m8 {
  margin: 8px;
}

.btn {
  width: 100%;
  margin-top: 24px;
}

.actions-container {
  margin: 8px;
  padding: 24px;
  border: 1px solid #d3d3d3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: scroll;
}

.action-head {
  margin-bottom: 16px;
  color: #2b2b2b;
  flex: 0 0;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.msg-container {
  position: absolute;
  bottom: 12px;
  left: 12px;
}

.msg-btn {
  font-size: 40px;
  color: rgb(206, 210, 213);
  transition: transform 200ms ease-in-out;
  opacity: 0.5;
}

.msg-btn:hover {
  transform: scale(1.05);
  opacity: 1;
}

.msg-list {
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border:0;
  border-radius: 20px;
  position: absolute;
  width: -webkit-max-content;
  width: max-content;
  max-width: 400px;
  max-height: 260px;
  overflow-y: auto;
  top: -5px;
  right: 50px;
  transition: transform 250ms ease-in-out;
  transform-origin: bottom left;
  translate: 100% -100%;
}

.content {
  position: relative;
}
.btn {
  width: 100%;
  margin-top: 24px;
}

.form-item{
    margin-bottom: 8px;
}

.actions-container{
  margin: 8px;
  padding: 24px;
  border: 1px solid #d3d3d3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: scroll;
}

.action-header{
  margin: 0px;
  color:#2b2b2b;
  flex: 0 0;

}

.action-body{
  margin-top: 15px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.add-stock-controller-item-title{
  margin: 0;
}

.switch-title{
  margin-right: 10px;
}

.add-stock-controller-item{
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
}

.add-stock-table{
  width: 100%;
  margin-top: 10px;
}

.add-stock-row{
  display: flex;
    justify-content: space-between;
    padding: 5px 0px;
}

.add-stock-btn{
  width: 100%;
  margin-top: 24px;
}

.add-stock-title{
  flex: 0 0;
}

.add-stock-switch{
  flex: 0 0;
}

.add-stock-item{
  flex: 1 0;
}

.input-item{
  width: 100%;
}

.postback-container{
  background: red;
  height: 100%;
  padding: 25px
}

.limit-order-collapse {
  border: none;
}

.limit-order-collapse .ant-collapse-item {
  border: none;
}

.limit-order-collapse .ant-collapse-header {
  padding-left: 0;
  border: none;
  background: white;
  font-style: italic;
}

.limit-order-collapse .ant-collapse-content {
  border: none;
}

@media screen and (max-width: 480px) {
  .add-stock-info-btn{
    display: none;
  }

  #postback-section {
    order: 1;
    margin-top: 16px;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.nav-link {
  color: #ffffff;
  font-weight: 600;
  font-size: 20px;
}

.container {
  min-height: 100vh;
}

.flex-center {
  display: flex;
  justify-content: space-around;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.flex-apart {
  display: flex;
  justify-content: space-between;
}

.mt8 {
  margin-top: 16px;
  justify-self: flex-end;
}

.ml16 {
  margin-left: 16px;
}

.mr8 {
  margin-right: 8px;
}

.ml8 {
  margin-left: 8px;
}

.ios-click {
  cursor: pointer;
}

.logo {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px 0;
}

.full-width {
  width: 95%;
  margin: 50px 2.5%;
}

.fw {
  width: 100%;
}

.auto-complete {
  width: 100%;
}

.m-lr-8 {
  margin-left: 8px;
  margin-right: 8px;
}

.text-14 {
  font-size: 14px;
}

.response-data-modal {
  max-width: 90vw;
}

.response-data-content {
  white-space: pre;
  overflow: auto;
  max-height: 60vh;
}

@media screen and (min-width: 768px) {
  .auto-complete {
    width: 95%;
  }
  .container {
    padding: 20px;
  }
  .response-data-modal {
    max-width: 60vw;
  }
}
@media screen and (max-width: 480px) {
  .container {
    width: 100%;
    margin: initial auto;
  }

  .xs-mt-15 {
    margin-top: 15px;
  }
}



